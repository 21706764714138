import { graphql, Link } from "gatsby"
import React from "react"
import { Col, Container, Image, Row } from "react-bootstrap"
import { PageLayout, PageTitle } from "../components"
import { SEO } from "../utils"

export default ({ data }) => {
  const MediaLink = ({ title, author, link }) => (
    <li key={title} className='text-left' style={{ color: "gray" }}>
      <a rel="noopener noreferrer" href={link}>
        {title}
      </a>
      &nbsp;-<i>{author}</i>
    </li>
  )

  const {
    author,
    occupation,
    readingList,
    gamingList,
    designations,
    unemployed,
  } = data.site.siteMetadata

  const bookLinks = readingList.map(book => MediaLink(book))
  const gamingLinks = gamingList.map(show => MediaLink(show))

  return (
    <PageLayout pageName="page-about">
      <SEO title="Biographie" />
      <PageTitle title="Biographie" />
      <Container>
        <Row>
          <Col md="6">
            <article className=" w-85 m-auto text-justify">
              <p className="text-center">
                {designations.map((attr, i) => (
                  <span key={attr}>
                    &nbsp;<b>{attr}</b>&nbsp;
                    {i < designations.length - 1 && <>||</>}
                  </span>
                ))}
              </p>
              <p className="mt-4 pt-1">
                Hello <span role="img"> 👋🏽!</span> Moi c'est{" "}
                <b>{`${author}`}</b>. Je suis &nbsp;
                <b>{occupation}</b> &nbsp; et je suis originaire de la région de
                L'EST du CAMEROUN <span role="img"> 🇨🇲</span>.
              </p>
              <p>
                Mes spécialités sont l'architecture logicielle, les Systèmes
                d'information , et les bases de données. J'aime dispenser des
                cours de développement Web aux personnes intéresées par les
                technologies.
              </p>
              <p>
                Depuis 2018, je travaille pour le gouvernement en tant
                qu'Analyste au Ministère de L' Agriculture et du Développement
                Rural
                <a target="_blank" href="https://www.minader.cm/">
                  <b> (MINADER)</b> .
                </a>
                &nbsp; Ma mission prinicipale est d'automatiser et d'optimiser
                le Système d'Information de Gestion des Ressources Humaines.
              </p>
              <p>
                Mon temps libre je le partage avec ma famille et mes amis. J'ai
                pris la pillule de Rouge <span role="img">​ 🔴​🆚​🔵​💊 ​</span>
                pour comprendre la MATRIX 😂😂 et revenir dans le monde réel.
                J'ai d'autres hobbies comme la musique, la lecture, les
                randonnés et le sport 🏃🏾‍♂️.
              </p>
              <p>
                Consultez mes{" "}
                <Link to="/projets">
                  <b>projets</b>
                </Link>{" "}
                pour voir ce que j'ai fait ! Ou consultez mon{" "}
                <Link to="/blog">
                  <b>blog</b>
                </Link>{" "}
                pour voir ce qui a récemment attiré mon attention !
              </p>
            </article>
          </Col>

          <Col md="6">
            <Image
              thumbnail
              src="images/regis-sourire-2.jpg"
              alt={author}
            />
          </Col>

          <article className="w-75 m-auto">
            {unemployed && (
              <>
                <hr />
                <p className="unemployed text-left">
                  <small>
                    <b>Je suis disponible pour des collaborations </b>! Si vous
                    aimez ce que vous <Link to="/cv">voyez</Link>, Entrons
                    en&nbsp;
                    <a
                      href="mailto:moafembe@gmail.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Contact
                    </a>
                    !
                  </small>
                </p>
              </>
            )}
            <hr />
            <h5 className="watch-list-title pt-4 text-left">
              Voici quelques livres de ma liste de lecture :
            </h5>
            <ul style={{ fontSize: "0.9rem", listStyle: "none" }}>
              {bookLinks}
            </ul>
            <h5 className="watch-list-title pt-4 text-left">
              Voici quelque jeux videos de ma liste de jeux
            </h5>
            <ul style={{ fontSize: "0.9rem", listStyle: "none" }}>
              {gamingLinks}
            </ul>
          </article>
        </Row>
      </Container>
    </PageLayout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        unemployed
        occupation
        author
        designations
        readingList {
          title
          author
          link
        }
        gamingList {
          title
          author
          link
        }
      }
    }
  }
`
